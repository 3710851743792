import React from "react";
import Navbar from "./navbar";
import Nnnnnn from "../../asset/img/Account/account-1.png";

const NavbarGround = () => {
  return (
    <>
      <div className="background-color-main">
        <Navbar />
      </div>
        <img src={Nnnnnn} alt="" className="img-fluid" />
    </>
  );
};

export default NavbarGround;
