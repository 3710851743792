import "./App.css";
import Home from "./Component/Pages/Home/home";
import OrderNow from "./Component/Pages/OrderNow/order";
import OrderNowPayment from "./Component/Pages/OrderNow/OrderPayment/orderpayment";
import OrderNowFinal from "./Component/Pages/OrderNow/Final payment/payment";
import SingleProduct from "./Component/Pages/SingleProduct/singleProduct";
import ErrorPage from "./Component/Common/404page/index";
import Cart from "./Component/Pages/Cart/cart";
import About from "./Component/Pages/About/about";
import Contact from "./Component/Pages/ContactUs/contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import ScrollToTop from "./Component/Common/ScrollToTop/scroll";
import Account from "../src/Component/Pages/Account/account";
import OrderNowFinalCase from "./Component/Pages/OrderNow/Final payment/paymentcase";

function App() {
  useEffect(() => {
    let isScrolling = false;
    const handleScroll = () => {
      if (!isScrolling) {
        isScrolling = true;
        setTimeout(() => {
          const header = document.getElementById("header");
          const topbar = document.getElementById("topbar");
          if (header && topbar) {
            const isHeaderScrolled = window.scrollY > 100;
            header.classList.toggle("header-scrolled", isHeaderScrolled);
            topbar.classList.toggle("topbar-scrolled", isHeaderScrolled);
          }
          isScrolling = false;
        }, 100);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <>
      <ToastContainer />
      <Router>
        <ScrollToTop /> {/* Place the ScrollToTop component here */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/order" element={<OrderNow />} />
          <Route path="/orderPayment" element={<OrderNowPayment />} />
          <Route path="/orderFinalUPI" element={<OrderNowFinal />} />
          <Route path="/orderFinalCASE" element={<OrderNowFinalCase />} />
          <Route path="/singleproduct/:id" element={<SingleProduct />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/account" element={<Account />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
